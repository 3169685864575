import React from 'react';
import { Button, Typography } from '@mui/material';
import { Icon } from "@iconify/react";
import TradeIcon from '@mui/icons-material/SwapHorizOutlined';
import NeoLogo from '../../assets/neo.png'

const handleWalletConnect = () => {
  console.log('wallet connect clicked')
}

export const ConnectWalletButton = ({ iconName, bgColor, hoverBg, border }) => (
  <Button
    variant="contained"
    color="primary"
    endIcon={<Icon icon={iconName || "bx:wallet"} style={{ marginLeft: '8px' }} />}
    sx={{
      display: 'flex',
      width: '236px',
      padding: '8px 24px',
      alignItems: 'center',
      borderRadius: '8px',
      background: bgColor || 'rgba(0, 0, 0, 0.25)',
      color: 'white',
      '&:hover': {
        background: hoverBg || 'rgba(0, 0, 0, 0.35)',
      },
      textTransform: 'none',
      border: border || 'none',
    }}
    onClick={handleWalletConnect}
  >
    <Typography variant='h6' fontWeight={700} >Connect Wallet</Typography>
  </Button>
);

export const TradeButton = ({ iconName }) => (
  <Button
    variant="contained"
    color="secondary"
    endIcon={iconName ? <Icon icon={iconName} style={{ marginLeft: '8px' }} /> : <TradeIcon sx={{ marginLeft: '8px' }} />}
    sx={{
      display: 'flex',
      padding: '8px 24px',
      alignItems: 'center',
      borderRadius: '8px',
      background: '#9747FF',
      color: 'white',
      '&:hover': {
        background: '#a55eff',
      },
      textTransform: 'none',
      marginLeft: '16px',
    }}
  >
    <Typography variant='h6' fontWeight={700}>Trade</Typography>
  </Button>
);

export const NeoChainDropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<img src={NeoLogo} style={{ width: '28px', }} alt="Neo" />}
        endIcon={<Icon icon={'dashicons:arrow-down'} width={24} />}
        onClick={handleClick}
        sx={{
          display: 'flex',
          background: 'linear-gradient(144deg, rgba(130, 233, 177, 0.25) 3.43%, rgba(160, 158, 255, 0.25) 78.81%)',
          color: '#fff',
          boxShadow: 'none',
          width: '236px',
          padding: '8px 24px',
          alignItems: 'center',
          borderRadius: '8px',
          justifyContent: 'space-between',
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.1)', // slight white overlay on hover
          },
          textTransform: 'none',
          marginRight: 2
        }}
      >
        <Typography variant='h6'>Neo Chain</Typography>
      </Button>
      {/* <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Option 1</MenuItem>
        <MenuItem onClick={handleClose}>Option 2</MenuItem>
        <MenuItem onClick={handleClose}>Option 3</MenuItem>
      </Menu> */}
    </>
  );
};
