import React from 'react';
import './styles.scss';
import { Typography } from '@mui/material';
import { ConnectWalletButton, TradeButton } from '../Buttons';
import CircleContainer from '../../assets/circle-container.svg'

const partnersLogos = [
  { src: "path-to-your-logo-1.png", alt: "Partner 1", angle: 0 },
  { src: "path-to-your-logo-2.png", alt: "Partner 2", angle: 60 },
  { src: "path-to-your-logo-3.png", alt: "Partner 3", angle: 120 },
];

const PartnersSection = () => {
  return (
    <div className="partners-container">
      <div className='our-partners'>
        <Typography variant='h2' fontWeight={700} color={'#03E599'}>Our Partners</Typography>
        <Typography variant='h5' fontWeight={700} color={'#F2F2F2'}>RootSwap Finance is in collaboration with leading organizations in the blockchain and DeFi space.</Typography>
        <Typography variant='body1' color={'#03E599'}>Together with our partners, we aim to drive innovation and foster the growth of the crypto ecosystem.</Typography>
        <div className='partner-buttons'>
          <ConnectWalletButton />
          <TradeButton />
        </div>
      </div>

      {/* <div className="circle-container">
        {partnersLogos.map((logo, index) => (
          <img
            key={index}
            src={logo.src}
            className="partners-logo"
            style={{ '--angle': `${logo.angle}deg` }}
            alt={logo.alt}
          />
        ))}
      </div> */}
      <div className='temp-circle'>
        <img src={CircleContainer} alt='Partners' />
      </div>
    </div>
  );
};

export default PartnersSection;
