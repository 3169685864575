import React from 'react';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import PriceButton from '../../assets/price-button.svg'
import PurchaseToken from '../../assets/purchase-token.svg'

const Footer = () => (
  <Box sx={{
    px: 6,
    py: 2,
    mt: 3
  }}>
    <Grid container spacing={2} justifyContent="space-between" alignItems="center" mb={5}>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" gutterBottom sx={{ textAlign: 'left' }}>
          The leading decentralized exchange (DEX) on Neo X.
        </Typography>
      </Grid>
      <Grid item xs={12} md={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <img src={PriceButton} alt='Token Price' />
      </Grid>
      <Grid item xs={12} md={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button>
          <img src={PurchaseToken} alt='Purchase Token' />
        </Button>
      </Grid>
    </Grid>
    <Grid container spacing={2} justifyContent="space-between" mb={8}>
      <Grid item xs={12} sm={2} textAlign={'left'}>
        <Typography variant="body2" color={'#03E599'} mb={3}>Trade</Typography>
        <Link variant='h6' href="#" color="#AD9FBF" fontWeight={500} sx={{
          textDecoration: 'none'
        }}>Swap</Link><br />
        <Link variant='h6' href="#" color="#AD9FBF" fontWeight={500} sx={{
          textDecoration: 'none'
        }}>Perpetual</Link><br />
        <Link variant='h6' href="#" color="#AD9FBF" fontWeight={500} sx={{
          textDecoration: 'none'
        }}>Liquidity</Link><br />
        <Link variant='h6' href="#" color="#AD9FBF" fontWeight={500} sx={{
          textDecoration: 'none'
        }}>Bridge</Link>
      </Grid>
      <Grid item xs={12} sm={2} textAlign={'left'}>
        <Typography variant="body2" color={'#03E599'} mb={3}>Earn</Typography>
        <Link variant='h6' href="#" color="#AD9FBF" fontWeight={500} sx={{
          textDecoration: 'none'
        }}>Pools</Link><br />
        <Link variant='h6' href="#" color="#AD9FBF" fontWeight={500} sx={{
          textDecoration: 'none'
        }}>Farm</Link><br />
        <Link variant='h6' href="#" color="#AD9FBF" fontWeight={500} sx={{
          textDecoration: 'none'
        }}>Liquid Staking</Link><br />
        <Link variant='h6' href="#" color="#AD9FBF" fontWeight={500} sx={{
          textDecoration: 'none'
        }}>Simple Staking</Link>
      </Grid>
      <Grid item xs={12} sm={2} textAlign={'left'}>
        <Typography variant="body2" color={'#03E599'} mb={3}>Game</Typography>
        <Link variant='h6' href="#" color="#AD9FBF" fontWeight={500} sx={{
          textDecoration: 'none'
        }}>Lottery</Link><br />
        <Link variant='h6' href="#" color="#AD9FBF" fontWeight={500} sx={{
          textDecoration: 'none'
        }}>Prediction</Link><br />
      </Grid>
      <Grid item xs={12} sm={2} textAlign={'left'}>
        <Typography variant="body2" color={'#03E599'} mb={3}>Community</Typography>
        <Link variant='h6' href='https://twitter.com/rootswapneo' color="#AD9FBF" fontWeight={500} sx={{
          textDecoration: 'none'
        }} target='_blank'>Twitter</Link><br />
        <Link variant='h6' href="#" color="#AD9FBF" fontWeight={500} sx={{
          textDecoration: 'none'
        }}>Telegram</Link><br />
        <Link variant='h6' href="#" color="#AD9FBF" fontWeight={500} sx={{
          textDecoration: 'none'
        }}>Discord</Link>
      </Grid>
      <Grid item xs={12} sm={2} textAlign={'left'}>
        <Typography variant="body2" color={'#03E599'} mb={3}>Wallets & Blockchain</Typography>
        <Link variant='h6' href="#" color="#AD9FBF" fontWeight={500} sx={{
          textDecoration: 'none'
        }}>Neo Wallet</Link><br />
        <Link variant='h6' href="#" color="#AD9FBF" fontWeight={500} sx={{
          textDecoration: 'none'
        }}>Neo Blockchain Explorer</Link><br />
        <Link variant='h6' href="#" color="#AD9FBF" fontWeight={500} sx={{
          textDecoration: 'none'
        }}>Neo EVM Documentation</Link>
      </Grid>
    </Grid>
    <hr style={{ color: '#03E599' }} />
    <Box sx={{
      borderTop: (theme) => `1px solid ${theme.palette.divider}`,
      mt: 2,
      pt: 1,
      textAlign: 'left'
    }}>
      <Typography variant="body2" mt={6}>
        © 2024 Rootswap Finance Ltd
      </Typography>
    </Box>
  </Box>
);

export default Footer;
