import React from 'react';
import { Box, Typography, IconButton, Stack, useTheme, useMediaQuery } from '@mui/material';
import { Icon } from "@iconify/react";
import Groot from '../../assets/groot-2.svg'

const CommunityCta = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 18
      }}
    >
      <Box
        sx={{
          background: 'linear-gradient(to bottom right, rgba(151, 71, 255, 0.25), rgba(165, 95, 255, 0.25))',
          borderRadius: '16px',
          p: isMobile ? 2 : 4,
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          gap: isMobile ? 2 : 4,
          width: '90%',
          height: '490px'
        }}
      >
        <Box sx={{
          textAlign: 'left',
          height: '250px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
          <Typography variant="h4" component="h2" color="#03E599" gutterBottom>
            Join our Community
          </Typography>
          <Typography variant="h6" fontWeight={400}>
            Connect with RootSwap Finance and be part of our<br /> vibrant community. Stay updated on the latest news,<br />
            discuss strategies, and engage with fellow users.
          </Typography>
          <Stack direction="row" spacing={2}>
            <IconButton aria-label="discord">
              <Icon icon={"skill-icons:discord"} fontSize={48} />
            </IconButton>
            <IconButton aria-label="telegram">
              <Icon icon={"logos:telegram"} fontSize={48} />
            </IconButton>
            <IconButton aria-label="twitter" href='https://twitter.com/rootswapneo' target='_blank'>
              <Icon icon={"skill-icons:twitter"} fontSize={48} />
            </IconButton>
            <IconButton aria-label="medium">
              <Icon icon={"fa6-brands:medium"} color='#fff' fontSize={48} />
            </IconButton>
            <IconButton aria-label="gitbook">
              <Icon icon={"devicon:gitbook"} fontSize={48} />
            </IconButton>
          </Stack>
        </Box>
        <Box component="img" src={Groot} alt="Groot" sx={{ width: isMobile ? '100%' : 'auto', position: 'relative', top: '3px' }} />
      </Box>
    </Box>
  );
};

export default CommunityCta;
