import './App.scss';
import React from "react";
import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Header from './components/Header';
import Hero from './components/Hero';
import FeaturedPools from './components/FeaturedPools';
import Ecosystem from './components/Ecosystem';
import Partners from './components/Partners';
import CommunityCta from './components/CommunityCta';
import Contribute from './components/Contribute';
import Footer from './components/Footer';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <Hero />
      <Box sx={{
        background: 'url(/images/background.jpg) no-repeat center center',
        backgroundSize: 'cover',
        color: theme.palette.common.white,
        textAlign: 'center',
      }}>
        <FeaturedPools />
        <Ecosystem />
        <Partners />
        <CommunityCta />
        <Contribute />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
