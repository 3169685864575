import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { ConnectWalletButton } from '../Buttons';

const Contribute = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        p: theme.spacing(3),
        background: 'url(/images/contribute.png) no-repeat',
        backgroundSize: 'cover',
        gap: theme.spacing(2),
        height: '550px',
        marginTop: 16
      }}
    >
      <Typography variant='h4' color={'#03E599'} sx={{ mb: 5 }}>
        Contribute to Neo’s smart economy and start trading,<br /> staking, and participating in RootSwap's ecosystem
      </Typography>
      <ConnectWalletButton border='1px solid #03E599' />
    </Box>
  );
};

export default Contribute;
