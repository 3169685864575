import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#03E599',
    },
    error: {
      main: '#ff0000',
    },
    background: {
      default: '#f4f4f4',
    },
    // You can add more colors here
  },
  typography: {
    fontFamily: '"Titillium Web", sans-serif',
    h2: {
      fontSize: 48,
    },
    h3: {
      fontFamily: '"Titillium Web", sans-serif',
      fontWeight: 700,
      fontSize: 40,
      color: '#03E599'
    },
    h4: {
      fontFamily: '"Titillium Web", sans-serif',
      fontWeight: 700,
      fontSize: 36,
      color: '#f2f2f2'
    },
    h5: {
      fontFamily: '"Space Grotesk", sans-serif',
      fontSize: 24,
      fontWeight: 500,
      color: '#fff'
    },
    h6: {
      fontFamily: '"Space Grotesk", sans-serif',
      fontSize: 20,
      fontWeight: 700
    },
    body1: {
      fontFamily: '"Space Grotesk", sans-serif',
      fontSize: 16,
    },
    body2: {
      fontFamily: '"Titillium Web", sans-serif',
      fontSize: 24,
      fontWeight: 600,
      color: '#fff'
    }
  },
  // You can add more theme options here
});

export default theme;
