import React from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import { ConnectWalletButton, TradeButton } from '../Buttons';

const HeroContainer = styled(Box)(({ theme }) => ({
  background: 'url(/images/background-hero.png) no-repeat center center fixed',
  backgroundSize: 'cover',
  color: theme.palette.common.white,
  minHeight: '951px',
  textAlign: 'center',
  padding: theme.spacing(10, 2),
}));

const Hero = () => {
  return (
    <HeroContainer>
      <div className='container h-100' style={{ position: 'relative', top: '294px', right: '72px' }}>
        <div className='row h-100'>
          <div className='col-lg-6 col-12 d-flex align-items-end'>
            <img src="/images/groot.png" alt="Logo" className="img-fluid" style={{ minHeight: 'calc(100% - 80px)', objectFit: 'contain' }} />
          </div>
          <div className='col-lg-6 col-12'>
            <Box style={{ maxWidth: '600px', textAlign: 'left', marginLeft: '40px' }}>
              <Typography variant="h2" component="h1" fontWeight={700} style={{ marginBottom: '36px' }} gutterBottom>
                Experience <span style={{ color: '#03E599' }}>Neo X's<br /></span> Leading DEX
              </Typography>
              <Typography variant="h5" style={{ marginBottom: '36px' }} gutterBottom>
                Trade with up to 50x leverage, obtain<br /> credit through overcollateralized loans and<br /> earn interest by providing liquidity on our<br /> all-in-one DEX
              </Typography>
              <Box sx={{ display: 'flex' }}>
                <ConnectWalletButton />
                <TradeButton />
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </HeroContainer>
  );
};

export default Hero;
