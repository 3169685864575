import React, { useEffect, useState, useRef } from 'react'
import logo_dark from '../../assets/rootswap-logo.png'
import { Icon } from '@iconify/react'
import { Link, Typography } from '@mui/material'
import { ConnectWalletButton, NeoChainDropdown } from '../Buttons'
import LanguageIcon from '../../assets/globe.png'
import SettingsIcon from '../../assets/settings.png'
import TokenLogo from '../../assets/token-logo.svg'
import MenuIcon from '../../assets/menu.svg'
import styles from './styles.scss';

export const Navbar = ({ scrollPos, animate = false }) => {
  const navLinkRef = useRef(null)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1149.98);
  const [menuOpen, setMenuOpen] = useState(false);

  const navItems = [
    { href: '#trade', text: 'Trade' },
    { href: '#earn', text: 'Earn' },
    { href: '#game', text: 'Game' },
    { href: '#docs', text: 'Docs' },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1149.98);
    };

    window.addEventListener('resize', handleResize);

    // Call the handler right away so state gets updated with initial window size
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <nav className={`${scrollPos > 440 ? 'sticky ml-auto' : 'ml-auto'} ${styles.nav} ${isMobile ? styles.collapsed : ''}`}
      data-aos={animate ? 'fade-down' : 'none'}
      data-aos-delay="300">
      <a href='/' className={`logo-container ${isMobile ? '' : 'flex-grow-1'}`}>
        <img src={logo_dark} alt='rootswap-logo' className='logo' />
      </a>
      {isMobile && (
        <div className={styles.burgerMenu}>
          <img src={MenuIcon} alt="Menu" style={{ cursor: 'pointer' }} onClick={() => setMenuOpen(!menuOpen)} />
        </div>
      )}
      {!isMobile && (
        <div className='d-flex gap-4'></div>
      )}
      {!isMobile && (
        <div className='nav-link' id='navLinks' ref={navLinkRef}>
          <Icon
            className='iconify close'
            icon='clarity:times-line'
          />
          <ul className={menuOpen ? styles.open : 'mb-0'} style={{ marginRight: 'auto', alignItems: 'center', position: 'relative' }} onClick={() => setMenuOpen(!menuOpen)}>
            {navItems.map((item) => (
              <li key={item.text}>
                <Link href={item.href} variant='body1'>
                  <span>{item.text}</span>
                  <Icon icon='flowbite:chevron-down-outline' />
                  <span className="link-underline"></span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
      {!isMobile && (
        <div className='d-flex align-items-center justify-content-between'>
          <img src={TokenLogo} alt="Token Logo" style={{ width: '24px', color: '#fff', marginRight: '8px' }} />
          <Typography variant='h5' fontWeight={700} style={{ width: '95px', marginRight: '8px' }}>$32.392</Typography>
          <img src={LanguageIcon} alt="Language" style={{ width: '48px', color: '#fff', marginRight: '2px' }} />
          <img src={SettingsIcon} alt="Settings" style={{ width: '48px', color: '#fff', marginRight: '8px' }} />
          <NeoChainDropdown />
          <ConnectWalletButton bgColor={'#9747FF'} hoverBg={'#a55eff'} />
        </div>
      )}
    </nav>
  )
}
