import React from 'react';
import { Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button } from '@mui/material';
import EthPair from '../../assets/eth-pair.svg'

const poolData = [
  { id: 1, pair: 'WBTC - USDT', liquidity: '$5,000,000', apy: '15.7%', action: 'Start farming' },
  { id: 2, pair: 'WBTC - USDC', liquidity: '$5,000,000', apy: '15.7%', action: 'Start farming' },
  { id: 3, pair: 'WETH - USDT', liquidity: '$5,000,000', apy: '15.7%', action: 'Start farming' },
  { id: 4, pair: 'WETH - USDC', liquidity: '$5,000,000', apy: '15.7%', action: 'Start farming' },
  { id: 5, pair: 'bNEO - USDT', liquidity: '$5,000,000', apy: '15.7%', action: 'Start farming' },
  { id: 6, pair: 'bNEO - USDC', liquidity: '$5,000,000', apy: '15.7%', action: 'Start farming' },
  { id: 7, pair: 'WBTC - WETH', liquidity: '$5,000,000', apy: '15.7%', action: 'Start farming' },
  { id: 8, pair: 'USDT - USDC', liquidity: '$5,000,000', apy: '15.7%', action: 'Start farming' },
];

const FeaturedPools = () => {
  return (
    <Container maxWidth="lg" sx={{ paddingY: 4 }}>
      <Typography variant="h3" gutterBottom component="div" sx={{ textAlign: 'center' }}>
        Featured Pools
      </Typography>
      <Typography variant="h5" gutterBottom component="div" sx={{ color: 'white', textAlign: 'center', marginBottom: 3 }}>
        Maximize Returns with Our Liquidity Pools
      </Typography>
      <TableContainer component={Paper} sx={{ background: 'rgba(255, 255, 255, 0.10)', borderRadius: 2 }}>
        <Table aria-label="featured pools">
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: '#fff' }}>
                <Typography variant="h6" gutterBottom sx={{ marginLeft: 8 }}>
                  Pools
                </Typography>
              </TableCell>
              <TableCell sx={{ color: '#fff' }} align="center">
                <Typography variant="h6" gutterBottom>
                  Total Liquidity
                </Typography>
              </TableCell>
              <TableCell sx={{ color: '#fff' }} align="center">
                <Typography variant="h6" gutterBottom>
                  APY
                </Typography>
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {poolData.map((pool) => (
              <TableRow key={pool.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, color: 'white' }}>
                <TableCell component="th" scope="row" style={{ fontFamily: 'Space Grotesk', fontSize: 16, fontWeight: 700, color: '#fff' }}>
                  <span style={{ marginRight: 16, color: '#03E599' }}>{pool.id}</span>
                  <img src={EthPair} alt='Trading pair' style={{ marginRight: 16 }} />
                  {pool.pair}
                </TableCell>
                <TableCell align="center" style={{ fontFamily: 'Space Grotesk', fontSize: 16, fontWeight: 700, color: '#fff' }}>{pool.liquidity}</TableCell>
                <TableCell align="center" style={{ fontFamily: 'Space Grotesk', fontSize: 16, fontWeight: 700, color: '#fff' }}>{pool.apy}</TableCell>
                <TableCell align="right">
                  <Button variant="contained" sx={{ fontFamily: 'Space Grotesk', fontSize: 16, fontWeight: 500, color: '#fff', textTransform: 'none', backgroundColor: 'rgba(0, 0, 0, 0.25)', '&:hover': { backgroundColor: '#a55eff' }, mr: 4, padding: '8px 16px' }}>
                    {pool.action}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default FeaturedPools;
