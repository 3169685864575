import React from 'react';
import { Box, Card, CardContent, Grid, Typography, Button, Link } from '@mui/material';
import Swap from '../../assets/swap.svg'
import Liquidity from '../../assets/liquidity.svg'
import Perpetual from '../../assets/perpetual.svg'
import Lending from '../../assets/lending.svg'
import ShareIcon from '../../assets/share.svg'
import Pools from '../../assets/pools.svg'
import Farm from '../../assets/farm.svg'
import LiquidStaking from '../../assets/liquid-staking.svg'
import SimpleStaking from '../../assets/simple-staking.svg'
import Lottery from '../../assets/lottery.svg'
import Predictions from '../../assets/predictions.svg'

const tradeData = [
  {
    categories: [
      {
        subtitle: "Trade",
        features: [
          { name: "Swap", description: `Instantly exchange   cryptocurrencies on the Neo blockchain and beyond`, icon: Swap, buttonText: "Trade now" },
          { name: "Liquidity", description: "Provide liquidity to earn rewards in decentralized pools on RootSwap", icon: Liquidity, buttonText: "Add liquidity" },
          { name: "Perpetual", description: "Engage in perpetual trading with advanced tools and real-time market data.", icon: Perpetual, buttonText: "Explore" },
          { name: "Lending", description: "Lend your assets. Provide liquidity to borrowers and receive interest in return.", icon: Lending, buttonText: "Start lending" },
        ],
      },
    ]
  }
];

const earnData = [
  {
    categories: [
      {
        subtitle: "Earn",
        features: [
          { name: "Pools", description: "Stake your assets in high-yield pools and earn attractive returns.", icon: Pools, buttonText: "Explore Pools" },
          { name: "Farm", description: "Participate in yield farming to earn rewards by providing liquidity.", icon: Farm, buttonText: "Start farming" },
          { name: "Liquid Staking", description: "Stake your assets and enjoy the benefits of liquid staking on RootSwap.", icon: LiquidStaking, buttonText: "Stake liquidity" },
          { name: "Simple Staking", description: "Earn rewards with flexible your assets by staking with withdrawal options.", icon: SimpleStaking, buttonText: "Stake now" },
        ],
      },
    ]
  }
];

const gamesData = [
  {
    categories: [
      {
        subtitle: "Games",
        features: [
          { name: "Lottery", description: "Join the excitement and stand a chance to win big in our crypto lotteries.", icon: Lottery, buttonText: "Play lottery" },
          { name: "Predictions", description: "Predict outcomes and compete for rewards in RootSwap's prediction market.", icon: Predictions, buttonText: "Get started" },
        ],
      },
    ]
  }
];

const EcosystemCard = ({ data }) => {
  return (
    <Box sx={{ background: 'rgba(255, 255, 255, 0.10)', padding: 4, width: '90%', mx: 'auto', mt: 6 }}>
      {data.map((section, index) => (
        <Card key={index} sx={{ marginBottom: 4, backgroundColor: "transparent", color: "white" }}>
          <CardContent>
            {section.categories.map((category, categoryIndex) => (
              <Box key={categoryIndex}>
                <Typography variant="h4" component="div" gutterBottom sx={{ textAlign: 'center' }}>
                  {category.subtitle}
                </Typography>
                <hr style={{ marginBottom: '48px' }} />
                <Grid container spacing={2} justifyContent="center">
                  {category.features.map((feature, featureIndex) => (
                    <EcosystemFeature key={featureIndex} feature={feature} />
                  ))}
                </Grid>
              </Box>
            ))}
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

const EcosystemFeature = ({ feature }) => {
  return (
    <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}>
      <img src={feature.icon} alt={feature.name} style={{ width: '180px', height: '200px', marginBottom: '16px', mixBlendMode: 'luminosity' }} />
      <Typography variant="body2" gutterBottom>
        {feature.name}
      </Typography>
      <Typography variant="body1">
        {feature.description}
      </Typography>
      <Button
        component={Link}
        to={feature.link}
        endIcon={<img src={ShareIcon} alt='Share' style={{ width: '20px', marginLeft: 6 }} />}
        sx={{
          color: '#03E599',
          marginTop: 2,
          textTransform: 'none'
        }}
      >
        <Typography variant="h6">{feature.buttonText}</Typography>
      </Button>
    </Grid>
  );
};

const Ecosystem = () => {
  return (
    <Box sx={{ mt: 15 }}>
      <Typography variant="h3" component="div" gutterBottom sx={{ textAlign: 'center' }}>
        Our Ecosystem
      </Typography>
      <EcosystemCard data={tradeData} />
      <EcosystemCard data={earnData} />
      <EcosystemCard data={gamesData} />
    </Box>
  );
};

export default Ecosystem;
