import React, { useEffect, useState } from "react";
// import { Icon } from "@iconify/react";
import { Navbar } from "./navbar";


const Header = () => {
  const [scrollPos, setScrollPos] = useState(0);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setScrollPos(window.scrollY);
    });
    return () => {
      document.removeEventListener("scroll", () => {
        setScrollPos(window.scrollY);
      });
    };
  }, []);
  // const aboutSection = document.querySelector("#about");

  // const showNext = () => {
  //   window.location.hash = "#about";
  //   if (aboutSection) aboutSection.scrollIntoView();
  // };

  return (
    <>
      <Navbar scrollPos={scrollPos} animate={true} />
    </>
  );
};

export default Header;
